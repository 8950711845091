import React, { useState } from "react";
import { ReadOnlyFieldBlock } from "../ReadOnlyFieldBlock";
import LineSeparator from "common/LineSeparator";
import { ExpandableTable } from "../ExpandableTable";
import { renderDollar } from "utils/stringFunctions";
import classNames from "classnames";
import "./styles.scss";
import { Skeleton } from "@enpowered/ui";
import { getAssetLifetime } from "@enpowered/payment-proposal-view";

export const adaptProposalInputsToAnnualValues = ({
  projectType,
  projectCost,
  costSavings,
  otherCostSavings,
  incentives,
  useEscalatingPayments,
  percentageOfSavings,
  currentUtilityCost,
  utilityCostEscalator,
  progressPayments,
  interestRate,
  systemDegradationRate,
  ...otherInputs
}) => ({
  ...otherInputs,
  projectType,
  projectCost,
  currentUtilityCost,
  costSavings,
  otherCostSavings,
  incentivesYear: incentives ?? [],
  utilityCostEscalator,
  interestRate,
  systemDegradationRate,
  useEscalatingPayments,
  percentageOfSavings: percentageOfSavings,
  progressPayments: Object.entries(progressPayments).reduce(
    (ac, [month, percentage]) =>
      month === "final"
        ? ac
        : [
            ...ac,
            {
              month: parseInt(month),
              percentage
            }
          ],
    []
  )
});

const BlankFieldValue = () => (
  <span className="blankFieldValue">No values yet</span>
);

export default function FinancingScheduleView({
  proposalInputs,
  calculations,
  className,
  areRequiredInputsAvailable = false,
  isPayable
}) {
  const getCashFlowEndTerm = schedule =>
    schedule.cumulativeCashflow[
      (schedule.annualPayments?.findIndex(p => !p) ??
        schedule.cumulativeCashflow?.length) - 1
    ];

  const [annualTableExpanded, setAnnualTableExpanded] = useState(false);
  const [monthlyTableExpanded, setMonthlyTableExpanded] = useState(false);

  const { projectCost, incentives, projectType } = proposalInputs;

  const {
    monthlySchedule,
    monthsUntilBreakEven,
    totalDepreciationSavings,
    constructionPeriodInterest,
    ROIOverAssetLifetime
  } = calculations;

  const {
    annualPayments,
    annualSavings,
    cumulativeCashflow,
    annualOtherSavings,
    netCashflow
  } = calculations.schedule;

  const totalSavingsDuringTerm =
    areRequiredInputsAvailable && calculations.schedule.cumulativeCashflow
      ? getCashFlowEndTerm(calculations.schedule)
      : -1;
  let firstLineTdCounter = 2;
  let secondLineTdCounter = 2;

  const assetLifeTime = getAssetLifetime(projectType);

  return (
    <div className={classNames("financingScheduleView", className)}>
      <table className="financingScheduleView-mainValuesTable w-full">
        <tbody>
          <tr>
            <td>
              <ReadOnlyFieldBlock
                label="Project Cost"
                value={
                  !areRequiredInputsAvailable || !isPayable ? (
                    <BlankFieldValue />
                  ) : (
                    renderDollar(projectCost)
                  )
                }
              />
            </td>
            <td>
              <ReadOnlyFieldBlock
                label="Project Term"
                value={
                  !areRequiredInputsAvailable || !isPayable ? (
                    <BlankFieldValue />
                  ) : (
                    `${Math.trunc(calculations.termMonths / 12)} yr${
                      calculations.termMonths % 12 > 0
                        ? ` ${calculations.termMonths % 12} mo`
                        : ""
                    }`
                  )
                }
              />
            </td>
            {(!areRequiredInputsAvailable || !!incentives?.length) &&
              firstLineTdCounter++ && (
                <td>
                  <ReadOnlyFieldBlock
                    label="Project Incentives Total"
                    value={
                      !areRequiredInputsAvailable || !isPayable ? (
                        <BlankFieldValue />
                      ) : (
                        renderDollar(
                          incentives.reduce(
                            (ac, i) => ac + i.amount + (i.otherYears ?? 0),
                            0
                          )
                        )
                      )
                    }
                  />
                </td>
              )}
            {(!areRequiredInputsAvailable || totalSavingsDuringTerm > 0) &&
              firstLineTdCounter++ && (
                <td>
                  <ReadOnlyFieldBlock
                    label="Net Savings during Term"
                    value={
                      !areRequiredInputsAvailable || !isPayable ? (
                        <BlankFieldValue />
                      ) : (
                        renderDollar(totalSavingsDuringTerm)
                      )
                    }
                  />
                </td>
              )}
            {calculations.monthsUntilBreakEven > 1 && firstLineTdCounter++ && (
              <td>
                <ReadOnlyFieldBlock
                  label="Payback Period"
                  value={
                    !areRequiredInputsAvailable || !isPayable ? (
                      <BlankFieldValue />
                    ) : monthsUntilBreakEven <= 1 ? (
                      "--"
                    ) : (
                      `${Math.trunc(monthsUntilBreakEven / 12)} yr${
                        monthsUntilBreakEven % 12 > 0
                          ? ` ${monthsUntilBreakEven % 12} mo`
                          : ""
                      }`
                    )
                  }
                />
              </td>
            )}
            {(!areRequiredInputsAvailable || totalDepreciationSavings > 0) &&
              firstLineTdCounter++ && (
                <td>
                  <ReadOnlyFieldBlock
                    label="Commercial Depreciation Savings"
                    value={
                      !areRequiredInputsAvailable || !isPayable ? (
                        <BlankFieldValue />
                      ) : (
                        renderDollar(totalDepreciationSavings)
                      )
                    }
                  />
                </td>
              )}
            {ROIOverAssetLifetime && firstLineTdCounter++ && (
              <td>
                <ReadOnlyFieldBlock
                  label={`ROI of Down Payment (${
                    assetLifeTime ? assetLifeTime + " yrs" : "asset life time"
                  })`}
                  value={
                    !areRequiredInputsAvailable || !isPayable ? (
                      <BlankFieldValue />
                    ) : (
                      ROIOverAssetLifetime.toFixed(2) + "%"
                    )
                  }
                />
              </td>
            )}
          </tr>
          <tr className="schedulingViewTr-firstYear header">
            <td colSpan={Math.max(firstLineTdCounter, 5)}>
              <h5>First Year Financial Estimates</h5>
            </td>
          </tr>
          <tr className="schedulingViewTr-firstYear">
            <td>
              <ReadOnlyFieldBlock
                label="Monthly Payments"
                value={
                  !areRequiredInputsAvailable || !isPayable ? (
                    <BlankFieldValue />
                  ) : (
                    renderDollar(calculations.monthlyPayment)
                  )
                }
              />
            </td>
            <td>
              <ReadOnlyFieldBlock
                label="Monthly Savings"
                value={
                  !areRequiredInputsAvailable || !isPayable ? (
                    <BlankFieldValue />
                  ) : (
                    renderDollar(calculations.monthlySavings)
                  )
                }
              />
            </td>
            {calculations.monthlySavings - calculations.monthlyPayment > 0 &&
              secondLineTdCounter++ && (
                <td>
                  <ReadOnlyFieldBlock
                    label="Net Savings"
                    value={
                      !areRequiredInputsAvailable || !isPayable ? (
                        <BlankFieldValue />
                      ) : (
                        renderDollar(
                          calculations.monthlySavings -
                            calculations.monthlyPayment
                        )
                      )
                    }
                  />
                </td>
              )}
            {(proposalInputs.downPayment ?? 0) > 0 && secondLineTdCounter++ && (
              <td>
                <ReadOnlyFieldBlock
                  label="Down Payment"
                  value={
                    !areRequiredInputsAvailable || !isPayable ? (
                      <BlankFieldValue />
                    ) : (
                      renderDollar(proposalInputs.downPayment)
                    )
                  }
                />
              </td>
            )}

            {(!areRequiredInputsAvailable || constructionPeriodInterest > 0) &&
              secondLineTdCounter++ && (
                <td>
                  <ReadOnlyFieldBlock
                    label="Accrued Int. during Construction"
                    value={
                      !areRequiredInputsAvailable || !isPayable ? (
                        <BlankFieldValue />
                      ) : (
                        renderDollar(constructionPeriodInterest)
                      )
                    }
                  />
                </td>
              )}
            {secondLineTdCounter < firstLineTdCounter
              ? Array(firstLineTdCounter - secondLineTdCounter)
                  .fill(0)
                  .map((_, key) => <td key={`emptyTd-${key}`}></td>)
              : ""}
          </tr>
        </tbody>
      </table>
      <LineSeparator />
      <h4>Annualized Payment Schedule</h4>
      <ExpandableTable
        className=""
        isExpanded={annualTableExpanded}
        rowsLimit={6}
        toggleExpand={() => setAnnualTableExpanded(!annualTableExpanded)}
        headers={[
          "Year",
          "Annual Savings",
          "Annual Payments",
          "Net Savings",
          "Cumulative Savings"
        ]}
      >
        {!areRequiredInputsAvailable || !isPayable
          ? [
              <tr key="tbl1-tr-skeleton">
                {Array(5)
                  .fill(0)
                  .map((_, idx) => (
                    <td key={`tbl1-td-skeleton-${idx}`}>
                      <Skeleton paragraph={true} className="rounded-lg" />
                    </td>
                  ))}
              </tr>
            ]
          : netCashflow.map(
              (netCashflowYear, idx) =>
                has2YearsPassedSinceLastPaymentAndBreakEven(
                  "year",
                  idx,
                  annualPayments,
                  cumulativeCashflow
                ) && (
                  <tr key={`annualScheduleLine-${idx}`}>
                    {annualPayments[idx] < 0 && annualPayments[idx + 1] >= 0 ? (
                      <td className="flex justify-beween">
                        <span className="mt-1">{idx + 1}</span>
                        <div className="greenTag">Payments Concluded</div>
                        <span></span>
                      </td>
                    ) : (
                      <td>{idx + 1}</td>
                    )}
                    <td>
                      {renderDollar(
                        annualSavings[idx] + annualOtherSavings[idx]
                      )}
                    </td>
                    <td>{renderDollar(annualPayments[idx])}</td>
                    <td
                      className={netCashflowYear < 0 ? "redCell" : "greenCell"}
                    >
                      {renderDollar(netCashflowYear)}
                    </td>
                    <td
                      className={
                        cumulativeCashflow[idx] < 0 ? "redCell" : "greenCell"
                      }
                    >
                      {renderDollar(cumulativeCashflow[idx])}
                    </td>
                  </tr>
                )
            )}
      </ExpandableTable>
      <h4>Monthly Payment Schedule</h4>
      <ExpandableTable
        isExpanded={monthlyTableExpanded}
        rowsLimit={12}
        toggleExpand={() => setMonthlyTableExpanded(!monthlyTableExpanded)}
        headers={[
          "Month",
          "Opening Balance",
          "Payment Made",
          "Interest Owed",
          "Principal Paid",
          "Additional Lump Sum Payments",
          "Closing Balance",
          "Savings",
          "Net Savings",
          "Cumulative Savings"
        ]}
      >
        {!areRequiredInputsAvailable || !isPayable
          ? [
              <tr key="tbl2-tr-skeleton">
                {Array(10)
                  .fill(0)
                  .map((_, idx) => (
                    <td key={`tbl2-td-skeleton-${idx}`}>
                      <Skeleton paragraph={true} className="rounded-lg" />
                    </td>
                  ))}
              </tr>
            ]
          : monthlySchedule.map(
              (
                {
                  month,
                  currentBalance,
                  monthlyPayments,
                  interestPaidMonthly,
                  principalPaid,
                  additionalPayment,
                  remainingBalance,
                  cumulativeSavings,
                  savings,
                  netSavings
                },
                idx
              ) =>
                has2YearsPassedSinceLastPaymentAndBreakEven(
                  "month",
                  idx,
                  annualPayments,
                  cumulativeCashflow
                ) && (
                  <tr key={`monthlyScheduleLine-${month}`}>
                    <td>{month}</td>
                    <td>{renderDollar(currentBalance)}</td>
                    <td>{renderDollar(monthlyPayments)}</td>
                    <td>{renderDollar(interestPaidMonthly)}</td>
                    <td>{renderDollar(principalPaid)}</td>
                    <td>{renderDollar(additionalPayment)}</td>
                    <td>{renderDollar(remainingBalance)}</td>
                    <td>{renderDollar(savings)}</td>
                    <td className={netSavings < 0 ? "redCell" : "greenCell"}>
                      {renderDollar(netSavings)}
                    </td>
                    <td
                      className={
                        cumulativeSavings < 0 ? "redCell" : "greenCell"
                      }
                    >
                      {renderDollar(cumulativeSavings)}
                    </td>
                  </tr>
                )
            )}
      </ExpandableTable>
    </div>
  );
}

/**
 *
 * @param {("month" | "year")} monthOrYear
 * @param {number} counter
 * @param {array<Number>} payments
 * @param {array<Number>} cumulativeSavings
 */
const has2YearsPassedSinceLastPaymentAndBreakEven = (
  monthOrYear,
  counter,
  payments,
  cumulativeSavings
) => {
  let yearMinus1 = 0;
  if (monthOrYear === "month") yearMinus1 = Math.trunc(counter / 12);
  else yearMinus1 = counter;

  return (
    yearMinus1 <= 3 ||
    (yearMinus1 > 3 &&
      (cumulativeSavings[yearMinus1 - 2] < 0 || payments[yearMinus1 - 2] < 0))
  );
};
