import React from "react";
import "./styles.scss";
import { ReactComponent as UploadIcon } from "assets/upload-cloud.svg";
import { ReactComponent as XIcon } from "assets/x.svg";
import { Spinner } from "@enpowered/ui";
import classNames from "classnames";

export const FILE_PREPARING_UPLOAD = "Preparing...";
export const FILE_UPLOADING = "Uploading file...";
export const FILE_VALIDATING = "Validating file...";
export const FILE_VALIDATED = "File Validated";
export const FILE_ERROR = "Error";

function UploadFiles({
  id,
  title,
  subtitle,
  className,
  classNameButton,
  files,
  setFiles,
  children,
  removeFile,
  disabled
}) {
  return (
    <div
      className={classNames(`uploadFiles p-6 rounded-lg`, className, {
        disabled
      })}
    >
      <div className="flex items-center justify-between mb-3">
        <div>
          <h4 className="font-normal ">{title}</h4>
          <p className="font-light">{subtitle}</p>
        </div>
        {!disabled && (
          <div>
            <label
              className={`cursor-pointer ${
                classNameButton ? classNameButton : ""
              }`}
              htmlFor={`files-${id}`}
            >
              Upload
            </label>
            <input
              className="absolute"
              id={`files-${id}`}
              type="file"
              hidden
              multiple
              onChange={async event => {
                const dupedFiles = Array.from(
                  event.currentTarget.files ?? []
                ).map(uploadedFile => {
                  if (
                    (files ?? []).find(
                      existingFile =>
                        existingFile.filename === uploadedFile.name
                    )
                  ) {
                    const modifiedFileName = `${
                      uploadedFile.name.split(".")[0]
                    } (${
                      files.filter(existingFile =>
                        existingFile.filename
                          .split(".")[0]
                          .startsWith(uploadedFile.name.split(".")[0])
                      ).length
                    }).${uploadedFile.name.split(".")[1]}`;
                    return new File([uploadedFile], modifiedFileName, {
                      type: uploadedFile.type,
                      lastModified: uploadedFile.lastModified
                    });
                  } else {
                    return uploadedFile;
                  }
                });
                setFiles(dupedFiles);
              }}
            />
          </div>
        )}
      </div>

      {files?.length ? (
        <div className="grid gap-y-4 gap-x-2 grid-cols-2">
          {files.map((file, idx) => (
            <IndividualFile
              key={"file-" + idx}
              filename={file.filename}
              status={file.status}
              disabled={disabled}
              removeFile={() => removeFile(file)}
            />
          ))}
        </div>
      ) : disabled ? (
        <div className="fontGray">No files uploaded</div>
      ) : (
        <div className="mt-5 flex items-center justify-center gap-2">
          <span className="fontGray">No files uploaded</span>
          <UploadIcon />
        </div>
      )}

      {children}
    </div>
  );
}

export default UploadFiles;

const IndividualFile = ({ filename, status, removeFile, disabled }) => {
  const showUploadingBar = status === FILE_UPLOADING;
  const showLoading = status === FILE_VALIDATING;
  const showRemoveButton = status === FILE_VALIDATED || status === FILE_ERROR;
  const labelText = status === FILE_VALIDATED ? filename : status;

  const styles = classNames(
    "file rounded-full w-full grid items-center gap-4",
    {
      validating: [FILE_VALIDATING, FILE_UPLOADING].includes(status),
      uploading: showUploadingBar
    }
  );

  return (
    <div className={styles}>
      <span className="truncate">{labelText}</span>
      {showUploadingBar && (
        <div className="w-full uploading-bar">
          <div className="w-full overflow-hidden">
            <div className="progress w-full h-full"></div>
          </div>
        </div>
      )}
      {showLoading && <Spinner borderWidth="2px" borderColor="#FFD204" />}
      {showRemoveButton && !disabled && (
        <button type="button" onClick={removeFile}>
          <XIcon />
        </button>
      )}
    </div>
  );
};
