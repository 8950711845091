import React, { useState } from "react";
import "./styles.scss";
import { ReactComponent as XIcon } from "assets/x.svg";
import { ReactComponent as ExclamationIcon } from "assets/exclamation.svg";
import { ReactComponent as RefreshIcon } from "assets/refresh.svg";
import UploadCloudIcon from "assets/upload-cloud2.png";
import { Spinner } from "@enpowered/ui";
import classNames from "classnames";
import Modal from "../../common/Modal";
import IconTextButton from "../../common/IconTextButton";
import { sleep } from "../../utils/fetch";

export const FILE_PREPARING_UPLOAD = "Preparing...";
export const FILE_UPLOADING = "Uploading file...";
export const FILE_VALIDATING = "Validating file...";
export const FILE_VALIDATED = "File Validated";
export const FILE_ERROR = "Error";
export const FILE_ERROR_BEFORE_UPLOADED = "ErrorBeforeUploaded";

export function ConfirmationModal({
  fileBeingDeleted,
  setFileBeingDeleted,
  onConfirm
}) {
  return (
    <Modal
      className={"confirmationModal bg-background-modal"}
      isOpen={!!fileBeingDeleted}
      centered={true}
    >
      <div>
        <h3 className="ml-3 my-6">Do you want to remove this file?</h3>
        <p className="ml-3 my-9">
          Once a file is deleted it cannot be restored
        </p>
        <div className="flex justify-end gap-4 mb-4 mx-3">
          <IconTextButton
            filled
            label="Cancel"
            onClick={() => setFileBeingDeleted(null)}
          />
          <IconTextButton
            primary
            filled
            label="Remove file"
            onClick={() => {
              onConfirm(fileBeingDeleted);
              setFileBeingDeleted(null);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
function BigUploadFiles({
  id,
  className,
  classNameButton,
  files = [],
  addFiles,
  children,
  disabled,
  onRemoveFile
}) {
  const [fileBeingDeleted, setFileBeingDeleted] = useState(null);
  const onFilesAdded = async event => {
    let dupedFiles = [],
      aux = [];
    if (event.dataTransfer?.items) {
      [...event.dataTransfer.items].forEach(item => {
        if (item.kind === "file") aux.push(item.getAsFile());
      });
    } else aux = Array.from(event.currentTarget.files ?? []);
    dupedFiles = aux.map(uploadedFile => {
      if (
        (files ?? []).find(
          existingFile => existingFile.filename === uploadedFile.name
        )
      ) {
        const getFileExtensionRegexWithDot = /(?:.(?!\.))+$/g;
        // const getFileExtensionRegex = /(?:[^.](?!\.))+$/g;
        const modifiedFileName = `${uploadedFile.name.split(getFileExtensionRegexWithDot)[0]} (${
          files.filter(existingFile =>
            existingFile.filename
              .split(getFileExtensionRegexWithDot)[0] // get the filename without the extension
              //( (?!\.) is a negative lookahead for a dot that is not followed by another dot)
              // (?:.(?!\.))+ is a non-capturing group that matches any character that is not followed by a dot
              .startsWith(
                uploadedFile.name.split(getFileExtensionRegexWithDot)[0]
              )
          ).length
        })${uploadedFile.name.match(getFileExtensionRegexWithDot)?.[0] ?? ".file"}`;
        return new File([uploadedFile], modifiedFileName, {
          type: uploadedFile.type,
          lastModified: uploadedFile.lastModified
        });
      } else {
        return uploadedFile;
      }
    });
    addFiles(dupedFiles);
  };
  return (
    <div
      className={classNames(`bigUploadFiles rounded-lg`, className, {
        disabled
      })}
    >
      {!disabled && (
        <div
          className="uploadCard"
          onDragOver={e => {
            e.preventDefault();
          }}
          onDrop={e => {
            if (!disabled) {
              onFilesAdded(e);
              e.preventDefault();
            }
          }}
        >
          <div>
            <ConfirmationModal
              fileBeingDeleted={fileBeingDeleted}
              setFileBeingDeleted={setFileBeingDeleted}
              onConfirm={onRemoveFile}
            />
            <h4 className="font-normal ">Upload files</h4>
            <img src={UploadCloudIcon} />
            <div>
              <span className="font-semibold pr-2">Drop files here</span>
              {" or "}
              <label
                className={`relative w-full h-full ${
                  classNameButton ? classNameButton : ""
                }`}
                htmlFor={`files-${id}`}
              >
                <input
                  className="absolute -top-2 opacity-0 w-full h-full"
                  id={`files-${id}`}
                  type="file"
                  hidden
                  multiple
                  onChange={onFilesAdded}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
                <span className="selectFilesButton">Select files</span>
              </label>
            </div>
          </div>
        </div>
      )}
      {children}
      {files?.length ? (
        <div className="flex flex-col gap-2 w-full">
          {files.map((file, idx) => (
            <IndividualFile
              key={"file-" + idx}
              filename={file.filename}
              uploadFileId={`files-${id}`}
              status={file.status}
              disabled={disabled}
              removeFile={({ filename, status }) => {
                if (status === FILE_ERROR_BEFORE_UPLOADED)
                  onRemoveFile({ filename, status });
                else setFileBeingDeleted({ filename, status });
              }}
            />
          ))}
        </div>
      ) : disabled ? (
        <div className="fontGray">No files uploaded</div>
      ) : (
        <div className="mt-5 flex w-full items-center justify-left font-light gap-2">
          <span className="fontGray">No files uploaded</span>
        </div>
      )}
    </div>
  );
}

export default BigUploadFiles;

export const IndividualFile = ({
  filename,
  status,
  removeFile,
  disabled,
  uploadFileId
}) => {
  const showUploadingBar = status === FILE_UPLOADING;
  const showLoading = status === FILE_VALIDATING;
  const showRemoveButton =
    !disabled &&
    (status === FILE_VALIDATED ||
      status === FILE_ERROR ||
      status === FILE_ERROR_BEFORE_UPLOADED);
  const showErrorIcon = status === FILE_ERROR_BEFORE_UPLOADED;
  const labelText =
    status === FILE_VALIDATED || status === FILE_ERROR
      ? filename
      : status === FILE_ERROR_BEFORE_UPLOADED
        ? "Error uploading file: " + filename
        : status;

  const styles = classNames(
    "bigUploadFiles-file justify-between rounded-full w-full items-center gap-4",
    {
      validating: [FILE_VALIDATING, FILE_UPLOADING].includes(status),
      validated: [FILE_VALIDATED, FILE_ERROR].includes(status),
      uploading: showUploadingBar
    }
  );

  return (
    <div className={styles}>
      <span className="truncate">
        {showErrorIcon && <ExclamationIcon className="inline-block" />}
        {labelText}
      </span>
      {showUploadingBar && (
        <div className="w-3/5 uploading-bar">
          <div className="w-full overflow-hidden">
            <div className="progress w-full h-full"></div>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center gap-1">
        {showLoading && <Spinner borderWidth="2px" borderColor="#FFD204" />}
        {showErrorIcon && (
          <label htmlFor={uploadFileId}>
            <RefreshIcon
              className="bigUploadFile-refreshIcon cursor-pointer mb-1"
              width={22}
              onClick={async () => {
                await sleep(100);
                return removeFile({ filename, status });
              }}
            />
          </label>
        )}
        {showRemoveButton && (
          <button
            type="button"
            className="mr-2"
            onClick={() => removeFile({ filename, status })}
          >
            <XIcon width={12} height={12} />
          </button>
        )}
      </div>
    </div>
  );
};
