import React from "react";
import Modal from "../../common/Modal";
import "./styles.scss";

function NotReadyForCreditAppModal({ onClick, modalOverlayRef }) {
  return (
    <Modal
      blurrableContentRef={modalOverlayRef}
      centered={true}
      className={"notReadyForCreditAppModal bg-background-modal"}
      isOpen={true}
    >
      <h3 className="mt-6">More Information Needed</h3>
      <p className="mt-5 mb-3 px-6">
        To start a new financing application, enter the <b>project cost</b> and{" "}
        <b>annual savings</b> amounts in the project estimate.
      </p>
      <div className="flex my-5 justify-center">
        <button
          className="px-3 s18 py-2 border border-en-yellow-400 rounded hover:bg-en-gray-100"
          onClick={onClick}
        >
          Update Estimate
        </button>
      </div>
    </Modal>
  );
}

export default NotReadyForCreditAppModal;
