import React, { useRef } from "react";
import "./styles.scss";
import { getMixpanel } from "../../../services/mixpanel";
import { createCreditApp } from "../../../services/CreditApps";
import imageDetails from "assets/illustration-start.svg";
import imageShare from "assets/illustration-share.svg";
import imageSubmit from "assets/illustration-submit.svg";
import IconTextButton from "common/IconTextButton";
import { useMutation } from "react-query";
import { Spinner } from "@enpowered/ui";
import { DateTime } from "luxon";
import NotReadyForCreditAppModal from "../../NotReadyForCreditAppModal";
import { FEATURE_PROJECT_ESTIMATE } from "../../../ProposalForm";
import { sendToHubspotIfFirstTime } from "../../../services/FinancingScenarios";

function StartCreditAppPage({
  proposal,
  setFieldValue,
  setMessage,
  areRequiredFieldsFilled,
  showRequiredFields,
  postFinancingScenarioUpdates
}) {
  const mixpanel = getMixpanel();
  const creditAppPageRef = useRef();
  const { mutate: startApplication, isLoading } = useMutation(() =>
    Promise.allSettled([
      createCreditApp(proposal.financingScenarioId),
      sendToHubspotIfFirstTime(
        postFinancingScenarioUpdates,
        proposal,
        setFieldValue
      )
    ])
      .then(([creditAppFulfilled]) => {
        const creditAppId = creditAppFulfilled.value?.creditAppId;
        if (creditAppId) {
          console.info("credit App created Id:", creditAppId);
          mixpanel.track("Financing Application Opened", {
            ["Project ID"]: proposal.financingScenarioId,
            ["Application ID"]: creditAppId,
            isStart: true
          });
          setFieldValue("creditAppId", creditAppId);
        } else setMessage("Error on creating credit app", "error");
      })
      .catch(err => {
        setMessage(
          "Error on creating credit app [201] - " +
            (err.message ?? err) +
            " (" +
            DateTime.utc().toString() +
            ")",
          "error"
        );
      })
  );
  return (
    <div className="startCreditAppPage p-8 flex justify-center items-center">
      <div className="h-full" ref={creditAppPageRef}>
        <div>
          <h2 className="pt-6 m-auto m-0">Applying For Financing Made Easy</h2>
          <p className="mb-2">
            Accelerate your {`clients'`} path to project financing with a
            secure, easy-to-use, self-service experience.
          </p>
          <div className="steps">
            <div className="step">
              <img src={imageShare} />
              <h4>Share with customers</h4>
              <p>
                Share the secure link with a customer or complete it on their
                behalf.
              </p>
            </div>
            <div className="step">
              <img src={imageDetails} />
              <h4>Provide project details</h4>
              <p>
                Provide the specific details about your project for funders to
                review.
              </p>
            </div>
            <div className="step">
              <img src={imageSubmit} />
              <h4>Submit for financing</h4>
              <p>
                EnPowered will connect with you on the results and the best path
                forward.
              </p>
            </div>
          </div>
          <IconTextButton
            label={
              isLoading ? (
                <Spinner className={"mx-6"} size={20} />
              ) : (
                "Start Application"
              )
            }
            className="startButton primary filled"
            onClick={() => {
              startApplication();
              mixpanel.track(`Finacing Application Opened`, {
                ["Project ID"]: proposal.financingScenarioId
              });
            }}
          />
        </div>
      </div>
      {!areRequiredFieldsFilled && (
        <NotReadyForCreditAppModal
          onClick={() => showRequiredFields(FEATURE_PROJECT_ESTIMATE)}
          isModalOpen={areRequiredFieldsFilled}
          modalOverlayRef={creditAppPageRef}
        />
      )}
    </div>
  );
}

export default StartCreditAppPage;
